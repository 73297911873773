import React, { FC } from 'react';
import { graphql } from 'gatsby';

interface YoutubeVideoProps {
  data: Queries.Component;
}

interface YoutubeVideoBlockProps {
  video_id: string;
}

export const YoutubeVideoBlock: FC<YoutubeVideoBlockProps> = ({ video_id }) => {
  return (
    <div className="iframe-wrapper">
      <iframe width="560" height="315" src={`https://www.youtube-nocookie.com/embed/${video_id}`} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
    </div>
  );
};

const YoutubeVideo: FC<YoutubeVideoProps> = ({ data }) => {
  const { title, video_id } = data as Queries.CustomPageYoutubeVideoFragment;

  if (!video_id) return null;
  return (
    <div className="youtube container">
      <div className="youtube__video">
        {title && <h2>{title}</h2>}
        <YoutubeVideoBlock video_id={video_id} />
      </div>
    </div>
  );
};

export const query = graphql`
  fragment CustomPageYoutubeVideo on CustomPage_youtube_video {
    id
    title
    type
    video_id
  }
`;

export default YoutubeVideo;
