import { GatsbyImage, getImage, IGatsbyImageData, ImageDataLike } from 'gatsby-plugin-image';
import React, { FC } from 'react';
import { graphql } from 'gatsby';
import { DEFAULT_ALT } from '../../utils/utils';
import { Link } from 'gatsby';

interface MerchSectionProps {
  data: Queries.Component;
}

type RenderMerchItemProps = {
  links: [Queries.Link] | null;
};

type MerchItemProps = {
  title: string | null;
  image_alt: string | null;
  image: {
    readonly childImageSharp: {
      readonly gatsbyImageData: IGatsbyImageData;
    } | null;
  } | null;
  links: [Queries.Link] | null;
};

export const MerchItem: FC<MerchItemProps> = ({ title, image_alt, image, links }) => {
  const imageData = image ? getImage(image.childImageSharp) : null;
  return (
    <li className="merch-item">
      {imageData && (
        <div className="image-wrapper">
          <GatsbyImage image={imageData} alt={image_alt || DEFAULT_ALT} className="image" objectFit="cover" />
        </div>
      )}
      {title && <h3 className="title">{title}</h3>}
      <ul className="buy-links">
        <RenderMerchItem links={links as [Queries.Link] | null}></RenderMerchItem>
      </ul>
    </li>
  );
};
const RenderMerchItem: FC<RenderMerchItemProps> = ({ links }) => {
  if (links) {
    return (
      <>
        {links.map((link) => (
          <li>
            <a href={link.url} target={link.open_in_new_tab ? '_blank' : ''} title={link?.label || ''}>
              {link.label}
            </a>
          </li>
        ))}
      </>
    );
  }
  return <></>;
};
const MerchSection: FC<MerchSectionProps> = ({ data }) => {
  const { title, items, links } = data as Queries.CustomPageMerchSectionFragment;

  return (
    <div className="merch container">
      {title && title !== '' && <h2 className="merch__title">{title}</h2>}
      <ul className="merch__items">
        {items?.map((item) => {
          const { image, image_alt, title, links } = item;
          return <MerchItem image={image} image_alt={image_alt} title={title} links={links as [Queries.Link] | null} />;
        })}
      </ul>
      {links && (
        <div className="merch__links">
          {links.map(
            (link) =>
              link && (
                <div className="music-grid__footer">
                  <Link to={link.url} className="button">
                    {link.label}
                  </Link>
                </div>
              )
          )}
        </div>
      )}
    </div>
  );
};

export const query = graphql`
  fragment CustomPageMerchSection on CustomPage_merch {
    id
    title
    type
    links {
      url
      label
      open_in_new_tab
    }
    items {
      title
      date
      links {
        url
        label
        open_in_new_tab
      }
      image {
        childImageSharp {
          gatsbyImageData(
            width: 424
            breakpoints: [195, 212, 251, 390, 413, 424, 502, 826]
            sizes: "(min-width: 1200px) 251px, (min-width: 991px) calc((100vw - 196px)/4), (min-width: 768px) calc((100vw - 132px)/3), (min-width: 450px) calc((100vw - 60px)/2), calc(100vw - 36px)"
            layout: CONSTRAINED
            formats: [AUTO]
          )
        }
      }
      image_alt
    }
  }
`;

export default MerchSection;
