import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React, { FC } from 'react';
import { graphql } from 'gatsby';
import { DEFAULT_ALT } from '../../utils/utils';
import Instagram from '../atoms/Instagram';

interface TeamSectionProps {
  data: Queries.Component;
}

const TeamSection: FC<TeamSectionProps> = ({ data }) => {
  const { title, team } = data as Queries.CustomPageTeamSectionFragment;

  return (
    <div className="team container">
      {title && title !== '' && <h2 className="team__title">{title}</h2>}
      <ul className="team__items">
        {team?.map((item) => {
          if (!item) return <></>;
          const { title, image, image_alt, image_hover, image_hover_alt, instagram } = item;
          const imageData = image ? getImage(image.childImageSharp) : null;
          const imageHoverData = image_hover ? getImage(image_hover.childImageSharp) : null;
          return (
            <li>
              {imageData && (
                <div className="image-wrapper">
                  <GatsbyImage image={imageData} alt={image_alt || DEFAULT_ALT} className="image" objectFit="cover" />
                  {imageHoverData && <GatsbyImage image={imageHoverData} alt={image_hover_alt || DEFAULT_ALT} className="image-hover" objectFit="cover" />}
                </div>
              )}
              <div className="details">
                {title && <h3 className="title">{title}</h3>}
                {instagram && (
                  <a className="follow" target={instagram.open_in_new_tab ? '__blank' : ''} href={instagram.url} title={instagram.label || ''}>
                    <Instagram />
                  </a>
                )}
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export const query = graphql`
  fragment CustomPageTeamSection on CustomPage_team {
    id
    type
    title
    team {
      title
      instagram {
        url
        label
        open_in_new_tab
      }
      image {
        childImageSharp {
          gatsbyImageData(width: 424, breakpoints: [195, 212, 356, 390, 413, 424, 712, 826], sizes: "(min-width: 1200px) 356px, (min-width: 768px) calc((100vw - 132px)/3), calc(100vw - 36px)", layout: FULL_WIDTH, formats: [AUTO])
        }
      }
      image_alt
      image_hover {
        childImageSharp {
          gatsbyImageData(width: 424, breakpoints: [195, 212, 356, 390, 413, 424, 712, 826], sizes: "(min-width: 1200px) 356px, (min-width: 768px) calc((100vw - 132px)/3), calc(100vw - 36px)", layout: FULL_WIDTH, formats: [AUTO])
        }
      }
      image_hover_alt
    }
  }
`;

export default TeamSection;
