import React, { FC } from 'react';

type YouTubeProps = {
  className?: string;
};

export const YouTubeRound: FC<YouTubeProps> = ({ className }) => {
  return (
    <svg className={className} width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path className="logo" d="M25.1274 50.0075C38.7979 50.0075 49.88 38.8129 49.88 25.0037C49.88 11.1945 38.7979 0 25.1274 0C11.457 0 0.374893 11.1945 0.374893 25.0037C0.374893 38.8129 11.457 50.0075 25.1274 50.0075Z" fill="#151916" />
      <path d="M25.1274 0C11.4548 0 0.374893 11.1924 0.374893 25.0037C0.374893 38.8151 11.4548 50.0075 25.1274 50.0075C38.8001 50.0075 49.88 38.8151 49.88 25.0037C49.88 11.1924 38.7927 0 25.1274 0ZM40.92 33.0175C40.5433 34.4501 39.4205 35.5768 38.0097 35.9573C35.4391 36.6512 25.1348 36.6512 25.1348 36.6512C25.1348 36.6512 14.8305 36.6512 12.2599 35.9573C10.8417 35.5768 9.72636 34.4426 9.34964 33.0175C8.66269 30.4208 8.66267 25.0037 8.66267 25.0037C8.66267 25.0037 8.66269 19.5866 9.34964 16.99C9.72636 15.5574 10.8417 14.4307 12.2599 14.0501C14.8305 13.3562 25.1348 13.3562 25.1348 13.3562C25.1348 13.3562 35.4391 13.3562 38.0097 14.0501C39.4279 14.4307 40.5433 15.5574 40.92 16.99C41.607 19.5866 41.607 25.0037 41.607 25.0037C41.607 25.0037 41.607 30.4208 40.92 33.0175Z" />
      <path d="M21.833 29.9955L30.3941 25.0037L21.833 20.0045V29.9955Z" />
    </svg>
  );
};

const YouTube: FC<YouTubeProps> = ({ className }) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="50" height="50" xmlSpace="preserve">
      <path d="M49 13c-.6-2.2-2.3-3.8-4.4-4.4-3.9-1-19.5-1-19.5-1s-15.6 0-19.5 1c-2.3.5-4 2.2-4.6 4.4-1 3.9-1 12-1 12s0 8.1 1 12c.6 2.2 2.3 3.8 4.4 4.4 3.9 1 19.5 1 19.5 1s15.6 0 19.5-1c2.2-.6 3.8-2.3 4.4-4.4 1-3.9 1-12 1-12s.2-8.1-.8-12zM20 32.5v-15L33 25l-13 7.5z" />
    </svg>
  );
};

export default YouTube;
