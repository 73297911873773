import React, { FC } from 'react';

interface FacebookProps {
  className?: string;
}

export const FacebookRound: FC<FacebookProps> = ({ className }) => {
  return (
    <svg className={className} width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path className="logo" d="M24.7525 50.0075C38.423 50.0075 49.5051 38.8129 49.5051 25.0037C49.5051 11.1945 38.423 0 24.7525 0C11.0821 0 0 11.1945 0 25.0037C0 38.8129 11.0821 50.0075 24.7525 50.0075Z" fill="#151916" />
      <path d="M24.7525 0C11.0799 0 0 11.1924 0 25.0037C0 37.4795 9.0486 47.8287 20.8819 49.7015V32.2265H14.5959V24.9963H20.8819V19.4896C20.8819 13.2219 24.5752 9.75974 30.2334 9.75974C32.9443 9.75974 35.7733 10.2447 35.7733 10.2447V16.4005H32.6488C29.576 16.4005 28.6157 18.3256 28.6157 20.3029V24.9963H35.4779L34.3773 32.2265H28.6083V49.6941C40.4417 47.8212 49.4903 37.472 49.4903 24.9963C49.4977 11.1924 38.4178 0 24.7525 0Z" />
    </svg>
  );
};

const Facebook: FC<FacebookProps> = ({ className }) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="50" height="50" xmlSpace="preserve">
      <path d="M19.6 50V28.1h-7.9v-9h7.9v-6.9C19.6 4.3 24.2 0 31.4 0c3.4 0 7 .6 7 .6v7.7h-3.9c-3.9 0-5.1 2.4-5.1 4.9v5.9H38l-1.4 9h-7.3V50h-9.7z" />
    </svg>
  );
};

export default Facebook;
