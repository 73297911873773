import React, { FC, useEffect, useState } from 'react';
import IconClose from './atoms/IconClose';

const GDPR: FC = () => {
  const [viewed, setViewed] = useState<boolean>();
  const handleClose: (e: React.MouseEvent) => void = () => {
    sessionStorage.setItem('mac_cookies', 'true');
    setViewed(true);
  };

  useEffect(() => {
    const cookie = sessionStorage.getItem('mac_cookies');
    if (cookie && cookie === 'true') {
      setViewed(true);
    } else {
      setViewed(false);
    }
  }, []);

  if (viewed === undefined || viewed === true) return <></>;
  return (
    <div className="gdpr">
      By using this website, you agree to our use of cookies. We use cookies to provide you with a great experience and to help our website run effectively.
      <button type="button" onClick={handleClose} className="gdpr__button">
        <IconClose />
      </button>
    </div>
  );
};

export default GDPR;
