import React, { FC } from 'react';

interface InstagramProps {
  className?: string;
}

export const InstagramRound: FC<InstagramProps> = ({ className }) => {
  return (
    <svg className={className} width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path className="logo" d="M24.8726 50.0075C38.543 50.0075 49.6251 38.8129 49.6251 25.0037C49.6251 11.1945 38.543 0 24.8726 0C11.2021 0 0.120041 11.1945 0.120041 25.0037C0.120041 38.8129 11.2021 50.0075 24.8726 50.0075Z" fill="#151916" />
      <path d="M24.8737 0C11.2085 0 0.128601 11.1924 0.128601 25.0037C0.128601 38.8151 11.2085 50.0075 24.8811 50.0075C38.5537 50.0075 49.6337 38.8151 49.6337 25.0037C49.6337 11.1924 38.5464 0 24.8737 0ZM41.2425 31.8609C41.1612 33.6293 40.8805 34.8381 40.4743 35.8976C40.0532 36.9945 39.4918 37.9197 38.5759 38.8449C37.66 39.7702 36.744 40.3373 35.6582 40.7626C34.6093 41.173 33.4127 41.4565 31.662 41.5386C29.904 41.6207 29.3426 41.6356 24.8737 41.6356C20.4048 41.6356 19.8435 41.6132 18.0854 41.5386C16.3348 41.4565 15.1382 41.173 14.0893 40.7626C13.0035 40.3373 12.0875 39.7702 11.1716 38.8449C10.2556 37.9197 9.69426 36.9945 9.27322 35.8976C8.86696 34.8381 8.58627 33.6293 8.50501 31.8609C8.42376 30.0851 8.40899 29.518 8.40899 25.0037C8.40899 20.4895 8.43115 19.9224 8.50501 18.1465C8.58627 16.3782 8.86696 15.1694 9.27322 14.1098C9.69426 13.013 10.2556 12.0877 11.1716 11.1625C12.0875 10.2373 13.0035 9.6702 14.0893 9.24489C15.1382 8.8345 16.3348 8.55096 18.0854 8.46889C19.8435 8.38681 20.4048 8.37188 24.8737 8.37188C29.3426 8.37188 29.904 8.39427 31.662 8.46889C33.4127 8.55096 34.6093 8.8345 35.6582 9.24489C36.744 9.6702 37.66 10.2373 38.5759 11.1625C39.4918 12.0877 40.0532 13.013 40.4743 14.1098C40.8805 15.1694 41.1612 16.3782 41.2425 18.1465C41.3237 19.9224 41.3385 20.4895 41.3385 25.0037C41.3385 29.518 41.3237 30.0851 41.2425 31.8609Z" />
      <path d="M37.7105 15.1918C37.415 14.4158 37.0531 13.8636 36.4769 13.2816C35.9008 12.6996 35.3542 12.3414 34.586 12.0355C34.0024 11.8042 33.1308 11.5356 31.5279 11.461C29.7921 11.3789 29.2676 11.364 24.8726 11.364C20.4775 11.364 19.9531 11.3789 18.2172 11.461C16.6143 11.5356 15.7427 11.8042 15.1592 12.0355C14.391 12.334 13.8444 12.6996 13.2682 13.2816C12.6921 13.8636 12.3375 14.4158 12.0347 15.1918C11.8057 15.7812 11.5397 16.6617 11.4659 18.2809C11.3846 20.0343 11.3699 20.5641 11.3699 25.0037C11.3699 29.4434 11.3846 29.9731 11.4659 31.7266C11.5397 33.3458 11.8057 34.2262 12.0347 34.8157C12.3301 35.5917 12.6921 36.1439 13.2682 36.7259C13.8444 37.3079 14.391 37.666 15.1592 37.9719C15.7427 38.2033 16.6143 38.4719 18.2172 38.5465C19.9531 38.6286 20.4775 38.6435 24.8726 38.6435C29.2676 38.6435 29.7921 38.6286 31.5279 38.5465C33.1308 38.4719 34.0024 38.2033 34.586 37.9719C35.3542 37.6735 35.9008 37.3079 36.4769 36.7259C37.0531 36.1439 37.4077 35.5917 37.7105 34.8157C37.9395 34.2262 38.2054 33.3458 38.2793 31.7266C38.3605 29.9731 38.3753 29.4434 38.3753 25.0037C38.3753 20.5641 38.3605 20.0343 38.2793 18.2809C38.2054 16.6542 37.9395 15.7738 37.7105 15.1918ZM24.8726 33.5398C20.2042 33.5398 16.4149 29.712 16.4149 24.9963C16.4149 20.2806 20.2042 16.4528 24.8726 16.4528C29.5409 16.4528 33.3302 20.2806 33.3302 24.9963C33.3302 29.7194 29.5483 33.5398 24.8726 33.5398ZM33.6626 18.1167C32.5694 18.1167 31.683 17.2213 31.683 16.117C31.683 15.0127 32.5694 14.1173 33.6626 14.1173C34.7559 14.1173 35.6423 15.0127 35.6423 16.117C35.6423 17.2213 34.7559 18.1167 33.6626 18.1167Z" />
      <path d="M24.8726 30.5477C27.9037 30.5477 30.3608 28.0656 30.3608 25.0037C30.3608 21.9419 27.9037 19.4598 24.8726 19.4598C21.8415 19.4598 19.3843 21.9419 19.3843 25.0037C19.3843 28.0656 21.8415 30.5477 24.8726 30.5477Z" />
    </svg>
  );
};

export const Instagram: FC<InstagramProps> = ({ className }) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="50" height="50" xmlSpace="preserve">
      <path d="M14.6.2c-2.6.1-4.4.5-6 1.1-1.7.7-3.1 1.5-4.4 2.9S1.9 7 1.3 8.7c-.6 1.6-1 3.4-1.2 6.1S0 18.3 0 25s0 7.6.2 10.3c.1 2.7.6 4.5 1.2 6.1.6 1.6 1.5 3 2.9 4.4s2.8 2.2 4.4 2.9c1.6.6 3.4 1 6.1 1.2 2.6.1 3.5.1 10.2.1s7.6 0 10.3-.2 4.5-.6 6.1-1.2c1.6-.6 3-1.5 4.4-2.9 1.4-1.4 2.2-2.8 2.9-4.4.6-1.6 1-3.4 1.2-6.1.1-2.6.1-3.5.1-10.2s0-7.6-.2-10.3c-.1-2.7-.6-4.5-1.2-6.1-.6-1.6-1.5-3-2.9-4.4S43 1.9 41.3 1.3C39.7.7 37.9.3 35.2.1 32.6 0 31.7 0 25 0s-7.7 0-10.4.2m.3 45.2c-2.4-.1-3.8-.5-4.6-.9-1.2-.5-2-1-2.9-1.9S6 40.9 5.5 39.8c-.3-.9-.8-2.2-.9-4.6-.1-2.7-.1-3.5-.1-10.2s0-7.5.1-10.1c.1-2.4.5-3.8.9-4.6.5-1.2 1-2 1.9-2.9S9.1 6 10.2 5.5c.9-.3 2.2-.8 4.6-.9 2.6-.1 3.4-.2 10.1-.2s7.5 0 10.1.1c2.4.1 3.8.5 4.6.9 1.2.5 2 1 2.9 1.9s1.4 1.7 1.9 2.9c.3.9.8 2.2.9 4.6.1 2.6.2 3.4.2 10.1s0 7.5-.1 10.1c-.1 2.4-.5 3.8-.9 4.6-.5 1.2-1 2-1.9 2.9-.9.9-1.7 1.4-2.9 1.9-.9.3-2.2.8-4.6.9-2.6.1-3.4.2-10.1.2-6.6 0-7.4 0-10.1-.1m20.4-33.8c0 1.7 1.3 3 3 3s3-1.3 3-3-1.3-3-3-3c-1.6 0-3 1.4-3 3M12.2 25c0 7.1 5.8 12.8 12.9 12.8 7.1 0 12.8-5.8 12.8-12.9 0-7.1-5.8-12.8-12.9-12.8S12.2 17.9 12.2 25m4.5 0c0-4.6 3.7-8.3 8.3-8.4 4.6 0 8.3 3.7 8.4 8.3 0 4.6-3.7 8.3-8.3 8.4-4.7 0-8.4-3.7-8.4-8.3" />
    </svg>
  );
};

export default Instagram;
