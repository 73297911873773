import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React, { FC } from 'react';
import { graphql } from 'gatsby';
import { DEFAULT_ALT } from '../../utils/utils';

interface BannerProps {
  data: Queries.Component;
}

interface RenderBannerItemProps {
  link: Queries.Link | null;
  children: React.ReactNode;
}

const RenderBannerItem: FC<RenderBannerItemProps> = ({ link, children }) => {
  if (link) {
    return (
      <a href={link.url} target={link.open_in_new_tab ? '_blank' : ''} title={link?.label || ''}>
        {children}
      </a>
    );
  }
  return <div>{children}</div>;
};

const Banner: FC<BannerProps> = ({ data }) => {
  const { title, link, image, image_alt } = data as Queries.CustomPageBannerFragment;
  const imageData = image ? getImage(image.childImageSharp) : null;

  return (
    <div className="banner">
      <div className="container">
        {title && <h2>{title}</h2>}
        <RenderBannerItem link={link as Queries.Link | null}>{imageData && <GatsbyImage image={imageData} alt={image_alt || DEFAULT_ALT} className="blog-header__image" objectFit="cover" />}</RenderBannerItem>
      </div>
    </div>
  );
};

export const query = graphql`
  fragment CustomPageBanner on CustomPage_banner {
    id
    title
    type
    link {
      url
      label
      open_in_new_tab
    }
    image {
      childImageSharp {
        gatsbyImageData(width: 1200, layout: FULL_WIDTH, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
      }
    }
    image_alt
  }
`;

export default Banner;
