import React, { FC } from 'react';

interface BandcampProps {
  className?: string;
}

const Bandcamp: FC<BandcampProps> = ({ className }) => {
  return (
    <svg className={className} width="50" height="50" viewBox="0 0 50 50" fill="#1DA0C3" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 39.3797L15.9211 10H50L34.0789 39.3797H0Z" />
    </svg>
  );
};

export default Bandcamp;
