import { Link } from 'gatsby';
import React, { FC, useState } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { mountSocialIcon } from './Footer';

interface NavigationItem {
  label: string;
  url: string;
  open_in_new_tab: boolean;
}

type SocialIconItem = {
  icon: string;
  link: {
    label: string;
    url: string;
    open_in_new_tab: boolean;
  };
};
interface HeaderProps {
  navigation: NavigationItem[];
  social_icons: SocialIconItem[];
}

const Header: FC<HeaderProps> = ({ navigation, social_icons }) => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <header className="header">
      <div className="header__top">
        <div className="container">
          <nav>
            {navigation.map((item, i) => {
              return item.open_in_new_tab ? (
                <a href={item.url} target="_blank" className="header__item__label" aria-label={item.label} key={i}>
                  {item.label}
                </a>
              ) : (
                <Link to={item.url} partiallyActive={true} className="header__item__label" activeClassName="header__item--active" aria-label={item.label} key={i}>
                  {item.label}
                </Link>
              );
            })}
          </nav>
          <nav className="social-icons">
            {social_icons?.map((item, i) => {
              return item.link.open_in_new_tab ? (
                <a href={item.link.url} target="_blank" className="social-icon" aria-label={item.link.label} key={i}>
                  {mountSocialIcon(item.icon)}
                </a>
              ) : (
                <Link to={item.link.url} aria-label={item.link.label} className="social-icon" key={i}>
                  {item.link.label}
                </Link>
              );
            })}
          </nav>
        </div>
      </div>
      <div className="container">
        <Link to="/" aria-label="Home">
          <StaticImage loading="eager" quality={90} width={120} src="../images/metal-against-coronavirus-logo.png" alt="Metal Against Coronavirus" />
        </Link>
      </div>
    </header>
  );
};

export default Header;
