import React, { FC } from 'react';

interface TwitterProps {
  className?: string;
}

export const TwitterRound: FC<TwitterProps> = ({ className }) => {
  return (
    <svg className={className} width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path className="logo" d="M25 50.0075C38.6704 50.0075 49.7525 38.8129 49.7525 25.0037C49.7525 11.1945 38.6704 0 25 0C11.3296 0 0.247482 11.1945 0.247482 25.0037C0.247482 38.8129 11.3296 50.0075 25 50.0075Z" fill="#151916" />
      <path d="M25 0C11.3348 0 0.247482 11.1924 0.247482 25.0037C0.247482 38.8151 11.3274 50.0075 25 50.0075C38.6726 50.0075 49.7525 38.8151 49.7525 25.0037C49.7525 11.1924 38.6726 0 25 0ZM37.4317 20.2582C37.4464 20.5193 37.4464 20.773 37.4464 21.0342C37.4464 28.9957 31.4485 38.1809 20.472 38.1809C17.1037 38.1809 13.9644 37.181 11.3274 35.4723C11.7928 35.5245 12.2729 35.5544 12.753 35.5544C15.5451 35.5544 18.1231 34.5919 20.1618 32.9727C17.5469 32.9205 15.3457 31.1819 14.5849 28.7867C14.9468 28.8539 15.3235 28.8987 15.7076 28.8987C16.2543 28.8987 16.7787 28.8241 17.281 28.6897C14.548 28.1376 12.4945 25.6977 12.4945 22.7802C12.4945 22.7503 12.4945 22.728 12.4945 22.7056C13.2996 23.1607 14.2156 23.4293 15.198 23.4592C13.5951 22.3773 12.5462 20.5342 12.5462 18.445C12.5462 17.3407 12.8416 16.3035 13.3513 15.4156C16.2912 19.0643 20.6936 21.4595 25.65 21.7132C25.5466 21.2729 25.4949 20.8103 25.4949 20.3402C25.4949 17.0124 28.1689 14.3113 31.4633 14.3113C33.177 14.3113 34.7282 15.0425 35.8214 16.214C37.1805 15.9454 38.4584 15.438 39.6107 14.7515C39.1675 16.1618 38.222 17.3407 36.9885 18.0869C38.1925 17.9376 39.3448 17.6168 40.4158 17.1392C39.6107 18.348 38.5987 19.4076 37.4317 20.2582Z" />
    </svg>
  );
};

const Twitter: FC<TwitterProps> = ({ className }) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="#1D9BF0" xmlSpace="preserve">
      <path d="M44.9 14.8v1.3c0 13.6-10.3 29.2-29.2 29.2-5.6 0-11-1.6-15.7-4.6.8.1 1.6.1 2.4.1 4.6 0 9.1-1.5 12.7-4.4-4.4-.1-8.2-2.9-9.6-7.1 1.5.3 3.1.2 4.6-.2C5.4 28.2 2 24 2 19.1V19c1.4.8 3 1.2 4.7 1.3-4.5-3-5.9-9-3.2-13.7C8.7 13 16.4 16.9 24.6 17.3c-.8-3.6.3-7.3 3-9.8 4.1-3.9 10.6-3.7 14.5.4 2.3-.5 4.5-1.3 6.5-2.5-.8 2.4-2.4 4.4-4.5 5.7 2-.2 4-.8 5.9-1.6-1.4 2.1-3.1 3.8-5.1 5.3z" />
    </svg>
  );
};

export default Twitter;
