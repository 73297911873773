import React, { FC, useEffect, useState } from 'react';
import Footer, { SocialItem } from './Footer';
import Header from './Header';
import '../styles/index.scss';
import HeaderData from '../data/header.json';
import FooterData from '../data/footer.json';
import { Helmet } from 'react-helmet';

type LayoutProps = {
  children: React.ReactNode;
};

const Layout: FC<LayoutProps> = ({ children }) => {
  const footerData: SocialItem = { ...FooterData } as unknown as SocialItem;

  return (
    <>
      <Helmet>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="" />
        <link href="https://fonts.googleapis.com/css2?family=Mulish:wght@300;400;600&display=swap" rel="stylesheet" />
        <link rel="apple-touch-icon" sizes="152x152" href="/apple-touch-icon.png" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#FFFFFF" />
        <meta name="msapplication-TileColor" content="#ba2600" />
      </Helmet>
      <Header navigation={HeaderData.navigation} social_icons={HeaderData.social_icons} />
      <main>{children}</main>
      <Footer footer={footerData} />
    </>
  );
};

export default Layout;
