import React, { FC } from 'react';
import Facebook, { FacebookRound } from './atoms/Facebook';
import Instagram, { InstagramRound } from './atoms/Instagram';
import { Link } from 'gatsby';
import YouTube, { YouTubeRound } from './atoms/YouTube';
import Bandcamp from './atoms/Bandcamp';
import Twitter, { TwitterRound } from './atoms/Twitter';
import Mail from './atoms/Mail';
import { StaticImage } from 'gatsby-plugin-image';
import GDPR from './GDPR';

export interface SocialItem {
  content_html: {
    details: string;
    disclaimer: string;
  };
  social_icons: [
    {
      icon: string;
      style?: string;
      link: {
        label: string;
        url: string;
        open_in_new_tab: boolean;
      };
    }
  ];
  footer_links: [
    {
      link: {
        label: string;
        url: string;
        open_in_new_tab: boolean;
      };
    }
  ];
}

export interface FooterProps {
  footer: SocialItem;
}

export const mountSocialIcon = (icon: string, style?: string) => {
  switch (icon) {
    case 'bandcamp':
      return <Bandcamp />;
    case 'twitter':
      if (style == 'round') {
        return <TwitterRound />;
      }
      return <Twitter />;
    case 'youtube':
      if (style == 'round') {
        return <YouTubeRound />;
      }
      return <YouTube />;
    case 'facebook':
      if (style == 'round') {
        return <FacebookRound />;
      }
      return <Facebook />;
    case 'instagram':
      if (style == 'round') {
        return <InstagramRound />;
      }
      return <Instagram />;
    case 'mail':
      return <Mail />;
    default:
      icon;
  }
};

const Footer: FC<FooterProps> = ({ footer }) => {
  return (
    <>
      <footer className="footer">
        <div className="footer__top">
          <div className="container">
            <div className="footer__logo">
              <Link to="/">
                <StaticImage width={120} src="../images/metal-against-coronavirus-logo.png" alt="Metal Against Coronavirus" />
              </Link>
            </div>
            <nav className="social-items">
              {footer.social_icons.map((item, i) => {
                return item.link.open_in_new_tab ? (
                  <a href={item.link.url} target="_blank" className="social-icon" aria-label={item.link.label} key={i}>
                    {mountSocialIcon(item.icon, item.style)}
                  </a>
                ) : (
                  <Link to={item.link.url} aria-label={item.link.label} className="social-icon" key={i}>
                    {item.link.label}
                  </Link>
                );
              })}
            </nav>
          </div>
        </div>
        <div className="footer__bottom">
          <div className="container">
            <div className="footer__copy" dangerouslySetInnerHTML={{ __html: footer.content_html.details?.replace('{ year }', `${new Date().getFullYear()}`) }} />
            <nav>
              {footer.footer_links.map((item, i) => {
                return item.link.open_in_new_tab ? (
                  <a href={item.link.url} target="_blank" className="footer__links" aria-label={item.link.label} key={i}>
                    {item.link.label}
                  </a>
                ) : (
                  <Link to={item.link.url} className="footer__links" activeClassName="footer__links--active" aria-label={item.link.label} key={i}>
                    {item.link.label}
                  </Link>
                );
              })}
            </nav>
          </div>
        </div>
      </footer>
      <GDPR />
    </>
  );
};

export default Footer;
