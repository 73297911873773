import { GatsbyImage, getImage, ImageDataLike } from 'gatsby-plugin-image';
import React, { FC } from 'react';
import { graphql } from 'gatsby';
import { DEFAULT_ALT } from '../../utils/utils';
import { Link } from 'gatsby';
import { link } from 'fs';

interface MusicGridSectionProps {
  data: Queries.Component;
}

type RenderMusicGridItemProps = {
  link: Queries.Link | null;
  children: React.ReactNode;
};

const RenderMusicGridItem: FC<RenderMusicGridItemProps> = ({ link, children }) => {
  if (link) {
    return (
      <li>
        <a href={link.url} target={link.open_in_new_tab ? '_blank' : ''} title={link?.label || ''}>
          {children}
        </a>
      </li>
    );
  }
  return <li>{children}</li>;
};
const MusicGridSection: FC<MusicGridSectionProps> = ({ data }) => {
  const { title, items, link } = data as Queries.CustomPageMusicGridSectionFragment;

  return (
    <div className="music-grid container">
      {title && title !== '' && <h2 className="music-grid__title">{title}</h2>}
      <ul className="music-grid__items">
        {items?.map((item) => {
          const { image, image_alt, title, link, date } = item;
          const imageData = image ? getImage(image.childImageSharp) : null;
          return (
            <RenderMusicGridItem link={link as Queries.Link | null}>
              {imageData && (
                <div className="image-wrapper">
                  <GatsbyImage image={imageData} alt={image_alt || DEFAULT_ALT} className="image" objectFit="cover" />
                </div>
              )}
              {title && <h3 className="title">{title}</h3>}
              {date && <div className="date">{date}</div>}
            </RenderMusicGridItem>
          );
        })}
      </ul>
      {link && (
        <div className="music-grid__footer">
          <Link to={link.url} className="button">
            {link.label}
          </Link>
        </div>
      )}
    </div>
  );
};

export const query = graphql`
  fragment CustomPageMusicGridSection on CustomPage_music_grid {
    id
    title
    type
    link {
      url
      label
      open_in_new_tab
    }
    items {
      title
      date
      link {
        url
        label
        open_in_new_tab
      }
      image {
        childImageSharp {
          gatsbyImageData(
            width: 424
            breakpoints: [195, 212, 251, 390, 413, 424, 502, 826]
            sizes: "(min-width: 1200px) 251px, (min-width: 991px) calc((100vw - 196px)/4), (min-width: 768px) calc((100vw - 132px)/3), (min-width: 450px) calc((100vw - 60px)/2), calc(100vw - 36px)"
            layout: CONSTRAINED
            formats: [AUTO]
          )
        }
      }
      image_alt
    }
  }
`;

export default MusicGridSection;
