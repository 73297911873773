import React, { FC } from 'react';
import { graphql, Link } from 'gatsby';
import SEO from '../components/Seo';
import MusicGridSection from '../components/sections/MusicGridSection';
import Layout from '../components/Layout';
import Banner from '../components/sections/Banner';
import YoutubeVideo from '../components/sections/YoutubeVideo';
import ReleaseContent from '../components/sections/ReleaseContent';
import Content from '../components/sections/Content';
import MerchSection from '../components/sections/Merch';
import TeamSection from '../components/sections/Team';

interface GeneralTemplateProps {
  readonly pageContext: {
    slug: string;
    title: string;
  };
  data: {
    customPage: Queries.CustomPage;
  };
}

export const query = graphql`
  query CustomPage($slug: String) {
    customPage(slug: { eq: $slug }) {
      slug
      title
      description
      components {
        ... on CustomPage_content {
          ...CustomPageContent
        }
        ... on CustomPage_music_grid {
          ...CustomPageMusicGridSection
        }
        ... on CustomPage_merch {
          ...CustomPageMerchSection
        }
        ... on CustomPage_banner {
          ...CustomPageBanner
        }
        ... on CustomPage_youtube_video {
          ...CustomPageYoutubeVideo
        }
        ... on CustomPage_release_content {
          ...CustomPageReleaseContent
        }
        ... on CustomPage_team {
          ...CustomPageTeamSection
        }
      }
    }
  }
`;

const GeneralTemplate: FC<GeneralTemplateProps> = ({ pageContext, data }) => {
  const { slug, title } = pageContext;
  const {
    customPage: { description, components },
  } = data;
  return (
    <Layout>
      <SEO title={title} description={description} />
      {slug.includes('releases/') && (
        <div className="breadcrumbs container">
          <Link to="/releases">Releases</Link>
          <span>/</span>
          {title}
        </div>
      )}
      {components &&
        components.map((component, i) => {
          switch (component?.type) {
            case 'content':
              return <Content data={component} key={i} />;
            case 'music_grid':
              return <MusicGridSection data={component} key={i} />;
            case 'merch':
              return <MerchSection data={component} key={i} />;
            case 'banner':
              return <Banner data={component} key={i} />;
            case 'team':
              return <TeamSection data={component} key={i} />;
            case 'youtube_video':
              return <YoutubeVideo data={component} key={i} />;
            case 'release_content':
              return <ReleaseContent title={title} data={component} key={i} />;
            default:
              return <div key={i}>{component.type}</div>;
          }
        })}
    </Layout>
  );
};

export default GeneralTemplate;
