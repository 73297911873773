import React, { FC } from 'react';
import { graphql } from 'gatsby';

interface ContentProps {
  data: Queries.Component;
}

const Content: FC<ContentProps> = ({ data }) => {
  const { content, css_classes } = data as Queries.CustomPageContentFragment;

  return (
    <div className="content">
      <div className="container">{content && <div className={css_classes ? css_classes : ''} dangerouslySetInnerHTML={{ __html: content }}></div>}</div>
    </div>
  );
};

export const query = graphql`
  fragment CustomPageContent on CustomPage_content {
    id
    type
    content
    css_classes
  }
`;

export default Content;
