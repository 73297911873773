import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

interface Meta {
  name?: string;
  property?: string;
  content: string;
}

interface SEOProps {
  title?: string;
  description?: string;
  lang?: string;
  meta?: Meta[];
}

// [TODO] Update icon.png to the social media image to be used
const SEO: FC<SEOProps> = ({ title, description, lang, meta }) => {
  const { site, metaImage } = useStaticQuery(
    graphql`
      query {
        metaImage: file(relativePath: { eq: "metal-against-coronavirus-social.jpg" }) {
          childImageSharp {
            resize(width: 1200, height: 630, fit: CONTAIN) {
              src
              width
              height
            }
          }
        }
        site {
          siteMetadata {
            title
            description
            siteUrl
            twitterCreator
            twitterSite
          }
        }
      }
    `
  );

  const metaDescription = description && description !== '' ? description : site.siteMetadata.description;
  const defaultTitle = site.siteMetadata?.title;

  let additionalMeta: Meta[] = meta || [];

  if (metaImage?.childImageSharp.resize) {
    additionalMeta = [
      {
        name: 'og:image:alt',
        content: title || site.siteMetadata?.title,
      },
      {
        property: 'og:image',
        content: `${site.siteMetadata.siteUrl}${metaImage.childImageSharp.resize.src}`,
      },
      {
        property: 'og:image:width',
        content: metaImage.childImageSharp.resize.width,
      },
      {
        property: 'og:image:height',
        content: metaImage.childImageSharp.resize.height,
      },
      ...additionalMeta,
    ];
  }

  return (
    <Helmet
      htmlAttributes={{
        lang: lang || 'en',
      }}
      title={title}
      titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : undefined}
      meta={[
        {
          name: 'description',
          content: metaDescription,
        },
        {
          property: 'og:title',
          content: title,
        },
        {
          property: 'og:description',
          content: metaDescription,
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          name: 'twitter:card',
          content: 'summary_large_image',
        },
        {
          name: 'twitter:creator',
          content: site.siteMetadata?.twitterCreator || '',
        },
        {
          name: 'twitter:site',
          content: site.siteMetadata?.twitterSite || '',
        },
        ...additionalMeta,
      ]}
    />
  );
};

export default SEO;
