import React, { FC } from 'react';

interface MailProps {
  className?: string;
}

const Mail: FC<MailProps> = ({ className }) => {
  return (
    <svg className={className} width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M25.2549 50.0075C38.9253 50.0075 50.0074 38.8129 50.0074 25.0037C50.0074 11.1945 38.9253 0 25.2549 0C11.5844 0 0.502319 11.1945 0.502319 25.0037C0.502319 38.8129 11.5844 50.0075 25.2549 50.0075Z" fill="#151916" />
      <path
        d="M27.0498 25.0634C26.4958 25.2798 25.9122 25.3917 25.3361 25.3917C24.3684 25.3917 23.4082 25.0933 22.6105 24.5187L11.6413 16.5945C11.6044 16.7139 11.5822 16.8408 11.5822 16.9602L11.5453 32.9876C11.5453 33.3159 11.6709 33.6293 11.8999 33.8606C12.1289 34.0919 12.4391 34.2262 12.7641 34.2262L37.7013 34.2859C37.7013 34.2859 37.7013 34.2859 37.7086 34.2859C38.0337 34.2859 38.3365 34.1591 38.5729 33.9278C38.8019 33.6965 38.9348 33.3831 38.9348 33.0548L38.9718 17.2139L27.914 24.6157C27.6407 24.7948 27.3453 24.944 27.0498 25.0634Z"
        fill="#BF2400"
      />
      <path d="M14.3892 15.729L23.9475 22.6384C24.7452 23.213 25.831 23.2353 26.6436 22.6832L36.9479 15.7812L14.3892 15.729Z" fill="#BF2400" />
      <path
        d="M25.2549 0C11.5822 0 0.502319 11.1924 0.502319 25.0037C0.502319 38.8151 11.5822 50.0075 25.2549 50.0075C38.9275 50.0075 50 38.8076 50 25.0037C50 11.1998 38.9201 0 25.2549 0ZM41.2173 33.0548C41.2173 34.0024 40.848 34.8978 40.1832 35.5619C39.836 35.9051 39.4372 36.1737 38.994 36.3528C38.5803 36.5169 38.1445 36.599 37.7013 36.599H37.6939L12.7567 36.5393C11.8186 36.5393 10.9322 36.1662 10.2748 35.4947C9.61002 34.8232 9.24806 33.9278 9.24806 32.9802L9.28501 16.9527C9.28501 16.0051 9.65434 15.1097 10.3191 14.4456C10.4373 14.3262 10.5629 14.2218 10.7106 14.1098C10.7402 14.08 10.7771 14.0576 10.814 14.0352C11.405 13.6248 12.0993 13.3935 12.8084 13.4084L37.7456 13.4681C38.6837 13.4681 39.5627 13.8412 40.2275 14.5128C40.8923 15.1843 41.2543 16.0797 41.2543 17.0273L41.2173 33.0548Z"
        fill="#BF2400"
      />
    </svg>
  );
};

export default Mail;
