import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React, { FC } from 'react';
import { graphql } from 'gatsby';
import { DEFAULT_ALT } from '../../utils/utils';
import moment from 'moment';
import Bandcamp from '../atoms/Bandcamp';
import { YoutubeVideoBlock } from './YoutubeVideo';
import { MerchItem } from './Merch';

interface ReleaseContentProps {
  title: String;
  data: Queries.Component;
}

const ReleaseContent: FC<ReleaseContentProps> = ({ title, data }) => {
  const { content, lyrics, credits, bandcamp_link, cover_image, cover_image_alt, team_image, team_image_alt, date, youtube_video_id, merch_title, merch_links, merch_image, merch_image_alt } = data as Queries.CustomPageReleaseContentFragment;
  const coverData = cover_image ? getImage(cover_image.childImageSharp) : null;
  const teamData = team_image ? getImage(team_image.childImageSharp) : null;

  return (
    <div className="release-content">
      <div className="container">
        <div className="release-content__intro">
          <div className="image">{coverData && <GatsbyImage image={coverData} alt={cover_image_alt || DEFAULT_ALT} objectFit="contain" />}</div>
          <div className="release-content__intro-content">
            {title && <h1 className="title">{title}</h1>}
            {date && <div className="date">{moment(date).format('MMMM Do YYYY')}</div>}
          </div>
          {bandcamp_link && (
            <a className="button" href={bandcamp_link.url} target={bandcamp_link.open_in_new_tab ? '__blank' : ''}>
              <Bandcamp />
              {bandcamp_link.label}
            </a>
          )}
        </div>
        {youtube_video_id && (
          <div className="youtube">
            <YoutubeVideoBlock video_id={youtube_video_id} />
          </div>
        )}
        <div className="release-content__layout">
          <div className="release-content__content">{content && <div dangerouslySetInnerHTML={{ __html: content }}></div>}</div>
          <div className="release-content__credits">
            {teamData && <GatsbyImage image={teamData} alt={team_image_alt || DEFAULT_ALT} className="team" objectFit="cover" />}
            {credits && <div className="" dangerouslySetInnerHTML={{ __html: credits }}></div>}
            {merch_image && (
              <ul className="merch-list">
                <MerchItem title={merch_title} links={merch_links as [Queries.Link] | null} image={merch_image} image_alt={merch_image_alt}></MerchItem>
              </ul>
            )}
          </div>
          <section className="release-content__lyrics">
            {lyrics && (
              <header>
                <h3>Lyrics</h3>
              </header>
            )}
            {lyrics && <div className="" dangerouslySetInnerHTML={{ __html: lyrics }}></div>}
          </section>
        </div>
      </div>
    </div>
  );
};

export const query = graphql`
  fragment CustomPageReleaseContent on CustomPage_release_content {
    id
    type
    content
    hashtags
    lyrics
    credits
    date
    bandcamp_link {
      url
      label
      open_in_new_tab
    }
    youtube_video_id
    cover_image {
      childImageSharp {
        gatsbyImageData(width: 200, breakpoints: [200, 298, 400, 457, 596, 914], sizes: "(min-width: 720px) 200px, calc(100vw - 92px)", layout: FULL_WIDTH, placeholder: DOMINANT_COLOR, formats: [AUTO, WEBP])
      }
    }
    cover_image_alt
    team_image {
      childImageSharp {
        gatsbyImageData(width: 400, breakpoints: [400, 800], sizes: "(min-width: 1200px) 400px, (min-width: 768px) calc((((100vw - 100px)/5) * 2) - 40px), calc(100vw - 92px)", layout: FULL_WIDTH, placeholder: DOMINANT_COLOR, formats: [AUTO, WEBP])
      }
    }
    team_image_alt
    merch_title
    merch_links {
      url
      label
      open_in_new_tab
    }
    merch_image {
      childImageSharp {
        gatsbyImageData(width: 400, breakpoints: [400, 800], sizes: "(min-width: 1200px) 400px, (min-width: 768px) calc((((100vw - 100px)/5) * 2) - 40px), calc(100vw - 92px)", layout: FULL_WIDTH, placeholder: DOMINANT_COLOR, formats: [AUTO, WEBP])
      }
    }
    merch_image_alt
  }
`;

export default ReleaseContent;
